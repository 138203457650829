import { getAuth } from 'firebase/auth';
import { gql, GraphQLClient } from 'graphql-request';
import { useQuery } from '@tanstack/react-query';
import { useUserRole } from '@/hooks/useAuth';
import { prefix, renameAndDestructure } from '@/utils';

const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);

export function useGetUsers() {
	const { data: userRoleData } = useUserRole();

	return useQuery({
		queryKey: ['get-users'],

		queryFn: async () => {
			const auth = getAuth();
			const token = await auth.currentUser?.getIdToken();

			graphQLClient.setHeader('authorization', `Bearer ${token}`);
			graphQLClient.setHeader('content-type', `application/json`);
			graphQLClient.setHeader('x-hasura-role', userRoleData?.user_role);

			const result = await graphQLClient.request(
				gql`
					query {
						${prefix}users(order_by: { user_first: asc }) {
							user_email
							user_firebase_id
							user_first
							user_id
							user_last
							user_role
							courses {
								course_id
								course_name
							}
							dept_chair {
								user_last
								user_first
								user_id
							}
							courses_users {
								courses_users_id
								user_id
								course_id
								course {
									course_name
								}
							}
						}
					}
				`
			);
			const { users } = renameAndDestructure(result, prefix);
			return users;
		},

		staleTime: Infinity,
		refetchOnMount: 'always',
	});
}

export function useDeptChairs() {
	const { data: userRoleData } = useUserRole();
	return useQuery({
		queryKey: ['get-dept-chairs'],

		queryFn: async () => {
			const auth = getAuth();
			const token = await auth.currentUser?.getIdToken();

			graphQLClient.setHeader('authorization', `Bearer ${token}`);
			graphQLClient.setHeader('content-type', `application/json`);
			graphQLClient.setHeader('x-hasura-role', userRoleData?.user_role);

			const result = await graphQLClient.request(
				gql`
					query {
						${prefix}users(
							where: { user_role: { _eq: "dept" } }
							order_by: { user_first: asc }
						) {
							user_first
							user_last
							user_id
							user_firebase_id
						}
					}
				`
			);
			const { users } = renameAndDestructure(result, prefix);
			return users;
		},

		staleTime: Infinity,
	});
}

export function useGetModalCourses() {
	const { data: userRoleData } = useUserRole();
	return useQuery({
		queryKey: ['get-modal-corses'],

		queryFn: async () => {
			const auth = getAuth();
			const token = await auth.currentUser?.getIdToken();

			graphQLClient.setHeader('authorization', `Bearer ${token}`);
			graphQLClient.setHeader('content-type', `application/json`);
			graphQLClient.setHeader('x-hasura-role', userRoleData?.user_role);

			const result = await graphQLClient.request(
				gql`
                    query {
                        ${prefix}courses(order_by: { course_name: asc }) {
                            course_name
                            course_id
                        }
                    }
                `
			);
			const { courses } = renameAndDestructure(result, prefix);
			return courses;
		},
	});
}
