/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { initializeApp, getApp, getApps } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import { getAuth, onAuthStateChanged, User as FirebaseUser } from 'firebase/auth';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';
import { createContext, useCallback, useEffect, useState } from 'react';
import { gql, GraphQLClient } from 'graphql-request';
import { useQuery } from '@tanstack/react-query';
import Loader from '@/components/Loader';
import { useAuth, useCurrentUser } from '@/hooks/useAuth';
import { useAlignmentStore } from '@/stores/alignmentStore';
import { adjustColor } from '@/helpers/adjust-color';
import colorJS from 'color';
import { prefix, renameAndDestructure } from '@/utils';
import packageJson from '../../package.json';

interface IGlobalContext {
	user: FirebaseUser;
	updateAuthState: (user: FirebaseUser | unknown, token?: string) => void;
	siteSettings: SiteSettings | undefined;
	disciplines: Discipline[] | undefined;
	divisions: Division[] | undefined;
	currentUser: User;
}

const GlobalContext = createContext({} as IGlobalContext);

const GlobalContextProvider = ({ ...props }) => {
	const { children } = props;
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [authState, setAuthState] = useState<{ user: FirebaseUser }>({
		user: {
			email: 'user@elevate.com',
			photoURL: '',
			uid: '',
		} as FirebaseUser,
	});
	const { user, initializing } = useAuth();
	const { data: currentUser, isInitialLoading } = useCurrentUser(!initializing);
	const updateAuthState = useCallback((user: FirebaseUser | unknown) => {
		setAuthState({ user } as any);
	}, []);

	const { data: settingsDivisionDisciplineData, isLoading: isSettingsLoading } = useQuery({
		queryKey: ['get-settings-divisions-disciplines'],

		queryFn: async () => {
			const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);
			graphQLClient.setHeader('content-type', `application/json`);

			const result = await graphQLClient.request(
				gql`
						query GetSettingsDivisionDiscipline {
								${prefix}disciplines(order_by: { name: asc }) {
									id
									name
									philosophy
									color
									image
								}
								${prefix}divisions(order_by: { name: asc }) {
									id
									name
								}
								${prefix}site_settings {
									statement
									statement_title
									id
									primary_color
									banner
									logo
								}
						}
					`
			);
			const { disciplines, divisions, site_settings } = renameAndDestructure(result, prefix);

			// this is specific to Urban community Schools. It sets specific discipline checkboxes
			if (import.meta.env.VITE_ORG === 'Urban Community') {
				disciplines.forEach((discipline: Discipline) => {
					const isUCSspecific = [
						'89e03aed-84a0-4a9a-8043-642706c3cc19', // ELA
						'f11f2a3d-b1aa-47c9-b861-06d30c9e8197', // Math
						'5d1a3da2-a6df-4b50-a1ff-09b772a1a196', // Science
						'dbae922c-5089-4934-84b8-99155b5a3f10', // Social Studies
					].includes(discipline.id);

					discipline.isSelected = isUCSspecific;
				});
			} else {
				disciplines.forEach((discipline: Discipline) => {
					discipline.isSelected = true;
				});
			}

			divisions.forEach((division: Division) => {
				division.isSelected = true;
			});
			useAlignmentStore.setState({ disciplines });
			useAlignmentStore.setState({ divisions });
			return { disciplines, divisions, siteSettings: site_settings?.[0] };
		},

		staleTime: Infinity,
		refetchOnMount: 'always',
	});

	useEffect(() => {
		if (!getApps()?.length) {
			initializeApp({
				apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
				authDomain: import.meta.env.VITE_FIREBASE_AUTHDOMAIN,
				databaseURL: import.meta.env.VITE_FIREBASE_DB_URL,
				projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
				storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
				messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
			});
		} else {
			getApp();
		}

		const auth = getAuth();

		const unsubscribe = onAuthStateChanged(auth, async (user) => {
			if (user) {
				const idTokenResult = await user.getIdTokenResult();
				const hasuraClaim = idTokenResult.claims['https://hasura.io/jwt/claims'];

				if (hasuraClaim) {
					updateAuthState({ user });
				} else {
					// Check if refresh is required.
					const databaseRef = getDatabase();
					const metadataRef = ref(databaseRef, `metadata/${user.uid}/refreshTime`);
					onValue(metadataRef, async (data) => {
						if (!data.exists) return;
						// Force refresh to pick up the latest custom claims changes.
						const token = await user.getIdToken(true);
						updateAuthState({ user, token });
					});
				}
			} else {
				updateAuthState({});
			}
		});

		return () => unsubscribe();
	}, [updateAuthState]);

	useEffect(() => {
		if (settingsDivisionDisciplineData) {
			const root = document.documentElement;
			const themeColor = settingsDivisionDisciplineData.siteSettings?.primary_color;

			root.style.setProperty('--org-color', themeColor ?? 'var(--elevate-primary)');
			if (themeColor) {
				root.style.setProperty(
					'--org-color-alt',
					adjustColor(themeColor, -20) ?? 'var(--elevate-primary)'
				);
			}
			root.style.setProperty(
				'--org-color-opacity',
				colorJS(themeColor).fade(0.5).toString() ?? 'var(--elevate-primary)'
			);
		}
	}, [settingsDivisionDisciplineData]);

	useEffect(() => {
		if (
			import.meta.env.MODE !== 'development' &&
			window.location.hostname !== 'elevate-development.vercel.app'
		) {
			LogRocket.init('cneeuh/elevate-hccid');
			LogRocket.identify('cneeuh/elevate-hccid', {
				email: user?.email ?? 'anonymous',
				instance: `${import.meta.env.VITE_ORG}`,
				release: packageJson.version,
			});
			LogRocket.getSessionURL((sessionURL) => {
				Sentry.configureScope((scope) => {
					scope.setExtra('sessionURL', sessionURL);
				});
			});
			Sentry.setUser({ email: user?.email ?? 'anonymous' });
		}
	}, [user]);

	if (isSettingsLoading || initializing || isInitialLoading) {
		return <Loader />;
	}

	return (
		<GlobalContext.Provider
			value={{
				updateAuthState,
				siteSettings: settingsDivisionDisciplineData?.siteSettings,
				disciplines: settingsDivisionDisciplineData?.disciplines,
				divisions: settingsDivisionDisciplineData?.divisions,
				// @ts-ignore
				user,
				currentUser,
			}}>
			{children}
		</GlobalContext.Provider>
	);
};

export default GlobalContext;

export { GlobalContextProvider };
