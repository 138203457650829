/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useRef, useState, SyntheticEvent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { StyledPrimaryButton, StyledSearch } from '@/Shared/StyledElements';
import { EditIcon, SearchIcon, TrashCanIcon } from '@/icons/index';
import EditCoursePanel from '@/components/CreateNewCoursePanel/CreateNewCoursePanel';
import Loader from '@/components/Loader';
import { showErrorToast } from '@/components/ToastNotification';
import { StyledH4 } from '@/Shared/Typography/typography';
import Tabs from '@/Pages/Courses/Tabs';
import Panel from '@/Pages/Courses/Panel';
import FadeIn from '@/components/animations/FadeIn';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { StyledCourseList, StyledCloseIcon, StyledListItem } from './CourseMapList.Styles';
import {
	useCourseMapFrag,
	useGetAdminPanelCourses,
	useGetDisciplineDivisions,
} from './CourseMapListContainer';
import CourseMapListForm from './CourseMapListForm';

interface CourseMapListProps {
	newCourse: any;
	selectedNode: any;
	treeData: any;
	userRole: string;
	updateTreeData(obj: any): void;
	passedEvent(): void;
	deleteCourse(a: Partial<Course>): void;
	checkTreeDiff(a: FieldValues): void;
}

const CourseMapList = ({
	newCourse,
	selectedNode,
	treeData,
	updateTreeData,
	passedEvent,
	deleteCourse,
	checkTreeDiff,
	userRole,
}: CourseMapListProps) => {
	const [courseOptions, setCourseOptions] = useState<Partial<Course>[]>();
	const [resultQty, setResultQty] = useState<number>(1);
	const [isAccordionActive, setIsAccordionActive] = useState(false);
	const [editingCourse, setEditingCourse] = useState<Partial<Course> | undefined>();
	const [isSuccess, setIsSuccess] = useState(false);
	const ref = useRef(courseOptions);
	const searchInput = useRef(document.createElement('input'));
	const { data: coursesList, isLoading } = useGetAdminPanelCourses();
	const { data: disciplineDivisionList } = useGetDisciplineDivisions();
	const queryClient = useQueryClient();
	const filterCourses = (e: SyntheticEvent) => {
		e.persist();
		const filterValue = (e.currentTarget as HTMLInputElement).value.toLowerCase();

		const list = [...coursesList].filter((course: any) =>
			course.course_name.toLowerCase().includes(filterValue)
		);

		if (filterValue === '') {
			setCourseOptions(ref.current);
		} else {
			setCourseOptions(list);
		}
		setResultQty(list?.length);
	};

	function isTypeDiscipline(type: Course | Discipline): type is Discipline {
		if ((type as Discipline).color) {
			return true;
		}
		return false;
	}

	const chooseOption = (node: Course | Discipline) => {
		// ?? better way of preventing multiple additions
		const activeIcon = document.querySelector('.active-icon');
		if (activeIcon === null || (node as Course)?.is_archived) return;
		if (isTypeDiscipline(node)) {
			newCourse.id = node.id;
			newCourse.tracker = node.id;
			newCourse.data.name = node.name;
			newCourse.data.id = node.id;
			newCourse.data.tracker = node.id;
			newCourse.data.division = '';
			newCourse.data.discipline = node.name;
			newCourse.data.color = node.color;
			newCourse.data.isDiscipline = true;
		} else {
			newCourse.id = node.course_id;
			newCourse.tracker = node.course_id;
			newCourse.data.name = node.course_name;
			newCourse.data.id = node.course_id;
			newCourse.data.tracker = node.course_id;
			newCourse.data.division = node?.courses_division?.division.name;
			newCourse.data.discipline = node?.courses_discipline?.discipline.name;
		}

		if (selectedNode.children) {
			selectedNode.children.push(newCourse);
		} else {
			selectedNode.children = [newCourse];
		}

		if (selectedNode.data.children) {
			selectedNode.data.children.push(newCourse.data);
		} else {
			selectedNode.data.children = [newCourse.data];
		}

		updateTreeData(treeData);
	};

	const { mutate: courseMutation, status } = useMutation({
		mutationFn: useCourseMapFrag,
		onSuccess: (results: any) => {
			setIsSuccess(true);
			setTimeout(() => {
				setIsSuccess(false);
			}, 2500);

			const previousCourseData = queryClient.getQueryData([
				'get-admin-panel-courses',
			]) as Partial<Course[]>;
			const course = results.update_courses.returning[0];
			const elementsIdx = previousCourseData.findIndex(
				(element: any) => element.course_id === course.course_id
			);
			previousCourseData[elementsIdx] = course;
			queryClient.setQueryData(['get-admin-panel-courses'], previousCourseData);
		},
		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const onSubmit: SubmitHandler<FieldValues> = (formFields) => {
		const { disciplineEdits, divisionEdits, courseNameEdits, grade, isAp, isArchived } =
			formFields;
		// currently disciplineId doesn't exist with course map object
		// use discipline name to get reference to discipline object
		const disciplineId =
			disciplineDivisionList?.disciplines?.find(
				(discipline: Discipline) => discipline.name === disciplineEdits
			)?.id ?? '';

		const divisionId =
			disciplineDivisionList?.divisions?.find(
				(division: Division) => division.name === divisionEdits
			)?.id ?? '';

		if (editingCourse?.course_id) {
			courseMutation({
				disciplineId,
				divisionId,
				courseName: courseNameEdits,
				courseId: editingCourse.course_id,
				userRole,
				grade,
				isAp,
				isArchived,
			});
			// course diff uses the course id to check if the course has been edited
			checkTreeDiff({ ...formFields, courseId: editingCourse.course_id });
		}
	};

	const handleEditCourse = useCallback((course: Partial<Course>) => {
		setEditingCourse({ ...course });
	}, []);

	useEffect(() => {
		searchInput?.current?.focus();
	}, []);

	useEffect(() => {
		const abortController = new AbortController();

		ref.current = coursesList;
		setCourseOptions(coursesList);
		return () => {
			abortController.abort();
		};
	}, [coursesList]);

	const archivedCourseList = [...(courseOptions ?? [])]?.filter((course) => course.is_archived);

	return (
		<StyledCourseList>
			{isLoading && <Loader size="small" />}
			{!isLoading && (
				<>
					<StyledCloseIcon passedEvent={() => passedEvent()} />
					{selectedNode?.depth === 0 && (
						<>
							<StyledH4 mb="var(--spacing-4)">Add Discipline</StyledH4>
							<div className="selection-container discipline-container">
								{disciplineDivisionList?.disciplines.map(
									(discipline: Discipline) => {
										const { id, name } = discipline;
										return (
											<StyledListItem key={`$panel-${id}`}>
												<div className="item-row">
													<div className="button-group">
														<button
															className="course-button"
															type="button"
															onClick={() => chooseOption(discipline)}
															data-id={id}>
															{name}
														</button>
													</div>
												</div>
											</StyledListItem>
										);
									}
								)}
							</div>
						</>
					)}
					{selectedNode?.depth !== 0 && (
						<>
							<StyledSearch>
								<SearchIcon />
								<input
									ref={searchInput}
									placeholder="Search..."
									type="text"
									onInput={(e) => filterCourses(e)}
								/>
							</StyledSearch>
							<div style={{ position: 'relative' }}>
								<StyledPrimaryButton
									size="small"
									onClick={() => setIsAccordionActive(!isAccordionActive)}>
									Add New Course
								</StyledPrimaryButton>
								<div
									className={
										isAccordionActive
											? 'accordion-body accordion-body--active'
											: 'accordion-body'
									}>
									{disciplineDivisionList?.disciplines && (
										<EditCoursePanel
											disciplines={disciplineDivisionList?.disciplines}
											divisions={disciplineDivisionList?.divisions}
											closeAccordion={() =>
												setIsAccordionActive(!isAccordionActive)
											}
										/>
									)}
								</div>
							</div>
							<Tabs selected={0} fillWidthButton>
								<Panel title="Courses">
									<div className="selection-container">
										{[
											{
												course_name: '- Spacer -',
												course_id: `${uuidv4()}`,
											},
											...(courseOptions ?? []),
										]
											?.filter((course) => !course.is_archived)
											?.map((course: Partial<Course>) => {
												const {
													course_id: courseId,
													course_name: courseName,
												} = course;

												const isSpacerBtn = courseName?.includes('Spacer');
												const classList = isSpacerBtn
													? 'course-button spacer-button'
													: 'course-button';

												return (
													<StyledListItem key={courseId}>
														<div className="item-row">
															<div className="button-group">
																<button
																	className={classList}
																	disabled={
																		editingCourse?.course_id ===
																		courseId
																	}
																	type="button"
																	onClick={() =>
																		chooseOption(course as any)
																	}
																	data-id={courseId}>
																	{courseName}
																</button>
																{!isSpacerBtn && (
																	<>
																		<EditIcon
																			disabled={
																				editingCourse?.course_id ===
																				courseId
																			}
																			width={18}
																			passedEvent={() =>
																				handleEditCourse(
																					course
																				)
																			}
																		/>
																		<TrashCanIcon
																			disabled={
																				editingCourse?.course_id ===
																				courseId
																			}
																			passedEvent={() =>
																				deleteCourse(course)
																			}
																		/>
																	</>
																)}
															</div>
														</div>
													</StyledListItem>
												);
											})}
										{resultQty === 0 && (
											<span className="no-matches-text">No Matches...</span>
										)}
									</div>
								</Panel>
								<Panel title="Archived Courses">
									<div className="selection-container selection-container--archive">
										{archivedCourseList?.length === 0 && resultQty !== 0 && (
											<span className="no-matches-text">
												No Archived Courses...
											</span>
										)}
										{archivedCourseList?.map((course: Partial<Course>) => {
											const { course_id: courseId, course_name: courseName } =
												course;

											const isSpacerBtn = courseName?.includes('Spacer');
											const classList = isSpacerBtn
												? 'course-button spacer-button'
												: 'course-button';

											return (
												<StyledListItem key={courseId}>
													<div className="item-row">
														<div className="button-group">
															<button
																data-archived={course?.is_archived}
																disabled
																className={classList}
																type="button"
																onClick={() =>
																	chooseOption(course as any)
																}
																data-id={courseId}
																key={courseId}>
																{courseName}
															</button>
															{!isSpacerBtn && (
																<>
																	<EditIcon
																		disabled={
																			editingCourse?.course_id ===
																			courseId
																		}
																		width={18}
																		passedEvent={() => {
																			handleEditCourse(
																				course
																			);
																		}}
																	/>
																	<TrashCanIcon
																		disabled={
																			editingCourse?.course_id ===
																			courseId
																		}
																		passedEvent={() =>
																			deleteCourse(course)
																		}
																	/>
																</>
															)}
														</div>
													</div>
												</StyledListItem>
											);
										})}
										{resultQty === 0 && (
											<span className="no-matches-text">No Matches...</span>
										)}
									</div>
								</Panel>
							</Tabs>
							{editingCourse?.course_id && (
								<FadeIn>
									<CourseMapListForm
										key={editingCourse?.course_id}
										onSubmit={onSubmit}
										editingCourse={editingCourse}
										isSuccess={isSuccess}
										saveStatus={status}
										setEditingCourse={setEditingCourse}
										disciplineList={disciplineDivisionList?.disciplines}
										divisionList={disciplineDivisionList?.divisions}
									/>
								</FadeIn>
							)}
						</>
					)}
				</>
			)}
		</StyledCourseList>
	);
};

export default CourseMapList;
