/* eslint-disable react/no-unstable-nested-components */
import { useState, useCallback, useMemo, useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Select from 'react-select';

import FadeIn from '@/components/animations/FadeIn';
import TableFilters from '@/components/TableFilters/TableFilters';
import Table from '@/components/Table';
import Loader from '@/components/Loader';
import { StyledColorIndicator, StyledPrimaryButton } from '@/Shared/StyledElements';
import ConfirmModal from '@/components/ConfirmModal/ConfirmModal';
import { showErrorToast, showSuccessToast } from '@/components/ToastNotification';
import { EditIcon, TrashCanIcon } from '@/icons';
import { themeSelectStyles } from '@/utils';
import { ConfirmationWarning } from './ConfirmationWarning';
import { StyledActionsContainer, StyledUtilsContainer } from './DisciplineListStyles';
import DisciplinesModal from './DisciplinesModal';
import {
	useGetDisciplines,
	deleteDiscipline,
	updateCourseDiscipline,
} from './DisciplinesContainers';

const Disciplines = () => {
	const [modalData, setModalData] = useState({
		isActive: false,
		isEditing: false,
		discipline: {} as Discipline,
	});
	const { data: disciplines, isFetching, isLoading } = useGetDisciplines();
	const [filteredData, setFilteredData] = useState([]);
	const [isConfirming, setIsConfirming] = useState(false);
	const [isValidated, setIsValidated] = useState(true);
	const [newDiscipline, setNewDiscipline] = useState({
		id: '',
		name: '',
	});
	const queryClient = useQueryClient();

	const handleSetData = useCallback((queryDisciplineData: any) => {
		setFilteredData(queryDisciplineData);
	}, []);

	const { mutateAsync: deleteDisciplineMutation } = useMutation({
		mutationFn: deleteDiscipline,
		onSuccess: (_, deletedValue) => {
			showSuccessToast('Discipline Deleted');

			// update query cache
			const previousDisciplineData = queryClient.getQueryData([
				'get-all-disciplines',
			]) as Discipline[];
			const updatedDisciplines = previousDisciplineData.filter(
				(discipline: Discipline) => discipline.id !== deletedValue.id
			);

			queryClient.setQueryData(['get-all-disciplines'], updatedDisciplines);

			setTimeout(() => {
				queryClient.invalidateQueries({
					queryKey: ['get-admin-panel-courses'],
				});
			}, 100);
		},
		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const { mutateAsync: courseDisciplineMutation } = useMutation({
		mutationFn: updateCourseDiscipline,
		onSuccess: () =>
			// courseDisciplines: CoursesDisciplineEntity[],
			// idObject: {
			// 	previousDiscipline: Discipline;
			// 	newDiscipline: Pick<Discipline, 'id' | 'name'>;
			// }
			{
				queryClient.invalidateQueries({
					queryKey: ['get-all-disciplines'],
				});

				// TODO: fix cache update
				// const { previousDiscipline, newDiscipline } = idObject;
				// showSuccessToast('Disciplines and Courses Updated');
				// // update query cache
				// const previousDisciplineData = queryClient.getQueryData([
				// 	'get-all-disciplines',
				// ]) as Discipline[];

				// const updatedCache = previousDisciplineData.map((discipline) => {
				// 	if (discipline.id === newDiscipline.id) {
				// 		return {
				// 			...discipline,
				// 			courses_disciplines: [
				// 				...(discipline.courses_disciplines ?? []),
				// 				...(previousDiscipline.courses_disciplines ?? []),
				// 			],
				// 		};
				// 	}
				// 	return discipline;
				// });

				// queryClient.setQueryData(['get-all-disciplines'], updatedCache);
			},
		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const isAssigned = useCallback(
		(discipline: Discipline) =>
			Boolean(discipline.courses_disciplines && discipline?.courses_disciplines?.length >= 1),
		[]
	);

	const removeDiscipline = async (discipline: Discipline) => {
		if (!isAssigned(discipline)) {
			await deleteDisciplineMutation({
				id: discipline.id,
			});
			return;
		}

		await courseDisciplineMutation({
			newDiscipline,
			previousDiscipline: discipline,
		});
	};

	const getOptionList = () => {
		const filteredDisciplines =
			disciplines?.filter(
				(discipline: Discipline) => discipline.id !== modalData.discipline.id
			) ?? [];

		const options = filteredDisciplines.map((discipline: Discipline) => ({
			value: discipline.name,
			label: discipline.name,
			id: discipline.id,
		}));

		return options;
	};

	const confirmDelete = useCallback(
		(discipline: Discipline) => {
			setIsValidated(!isAssigned(discipline));
			setModalData({ isActive: false, isEditing: true, discipline });
			setIsConfirming(true);
		},
		[isAssigned]
	);

	useEffect(() => {
		const abortController = new AbortController();
		return () => {
			abortController.abort();
		};
	}, [disciplines]);

	const queryData = useMemo(
		() =>
			disciplines?.map((discipline: Discipline) => ({
				name: discipline.name,
				numberOfCourses: discipline.courses_disciplines?.length ?? 0,
				color: discipline.color,
				actions: discipline,
				idColumn: discipline.id,
			})),
		[disciplines]
	);

	const columns = useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'name',
				sortType: 'basic',
			},
			{
				Header: 'Color',
				accessor: 'color',
				sortType: 'basic',
				alignment: 'center',
				Cell(cellData: any) {
					const { value } = cellData;
					return <StyledColorIndicator color={value} />;
				},
			},
			{
				Header: 'Assigned Courses',
				accessor: 'numberOfCourses',
				alignment: 'center',
				Cell(cellData: any) {
					const { value } = cellData;
					return <div style={{ textAlign: 'center' }}>{value}</div>;
				},
			},
			{
				Header: 'Actions',
				accessor: 'actionsColumn',
				alignment: 'center',
				Cell(cellData: any) {
					const { rowData } = cellData;
					const updateDisciplineModal = () => {
						setModalData({
							isActive: true,
							isEditing: true,
							discipline: rowData.actions,
						});
					};

					return (
						<StyledActionsContainer>
							<EditIcon width={18} passedEvent={updateDisciplineModal} />
							<TrashCanIcon
								passedEvent={() => {
									confirmDelete(rowData.actions);
								}}
							/>
						</StyledActionsContainer>
					);
				},
			},
		],
		[confirmDelete]
	);

	if (isLoading) return <Loader />;
	return (
		<>
			{isConfirming && (
				<ConfirmModal
					modalActive={isConfirming}
					onConfirm={() => removeDiscipline(modalData.discipline)}
					onCancel={() => setIsConfirming(false)}
					triggerModal={() => setIsConfirming(false)}
					message="Are you sure you want to delete the following discipline?"
					selectedData={modalData.discipline?.name}
					validated={isValidated}>
					{isAssigned(modalData.discipline) && (
						<>
							<ConfirmationWarning discipline={modalData.discipline} />
							<Select
								data-testid="filterSelect"
								styles={themeSelectStyles}
								placeholder="Select a new discipline"
								onChange={(option) => {
									setNewDiscipline({
										id: `${option?.id}`,
										name: `${option?.label}`,
									});
									setIsValidated(true);
								}}
								isClearable
								options={[...getOptionList()]}
								noOptionsMessage={() => 'No Disciplines Found'}
							/>
						</>
					)}
				</ConfirmModal>
			)}
			{modalData.isActive && (
				<DisciplinesModal
					modalData={modalData}
					allDisciplines={disciplines}
					triggerModal={() =>
						setModalData({
							isActive: false,
							isEditing: false,
							discipline: {} as Discipline,
						})
					}
				/>
			)}
			<StyledUtilsContainer>
				<StyledPrimaryButton
					size="small"
					onClick={() => {
						setModalData({
							isActive: true,
							isEditing: false,
							discipline: {} as Discipline,
						});
					}}>
					Add New Discipline
				</StyledPrimaryButton>
				{queryData && (
					<TableFilters
						data={queryData}
						handleSetData={handleSetData}
						searchColumn={['nameColumn']}
						filteredColumn="nameColumn"
						resultQty={filteredData.length}
						dropdown={false}
					/>
				)}
			</StyledUtilsContainer>
			{!isFetching && (
				<FadeIn style={{ height: '100%' }}>
					<Table data={filteredData} columns={columns} />
				</FadeIn>
			)}
		</>
	);
};

export default Disciplines;
