import { create } from 'zustand';
import { hierarchy as d3Hierarchy } from 'd3-hierarchy';
import { tree as d3Tree } from 'd3';
import { group as d3Group } from 'd3-array';
import { CourseNodeBase } from '@/components/CourseMap/coursemap.types';

export interface Details {
	showApOnly: boolean;
	showDescription: boolean;
	showOutcomes: boolean;
	showResources: boolean;
	showUnits: boolean;
}
interface State {
	disciplines: Discipline[];
	updateDisciplines: (disciplines: any) => void;
	divisions: Division[];
	updateDivisions: (divisions: any) => void;
	details: Details;
	toggleBoolean: (key: keyof State['details']) => void;
	toggleAllDisciplines: () => void;
	alignmentCoursesStore: Course[];
	updateAlignmentCourses: (alignmentCourses: Course[]) => void;
	mapDataStore: any;
	updateMapData: <T>(mapData: T) => void;
	courseHashMapStore: Map<string, any>;
	prunedAlignmentStore: Course[];
}

const markSelected = (array: Discipline[] | Division[], reference: Discipline | Division) => {
	return [...array].map((obj) => {
		if (obj.id === reference.id) {
			return { ...obj, isSelected: !obj.isSelected };
		}

		return obj;
	});
};

function hasArrayChildren(obj: unknown): obj is CourseNodeBase {
	return (
		(obj as CourseNodeBase)?.children !== undefined &&
		Array.isArray((obj as CourseNodeBase).children)
	);
}

export const useAlignmentStore = create<State>((set, get) => ({
	mapDataStore: undefined,
	courseHashMapStore: new Map<string, CourseNodeBase[]>(),
	alignmentCoursesStore: [],
	prunedAlignmentStore: [],
	disciplines: [],
	updateMapData: <T,>(mapDataStore: T) => {
		const root = d3Hierarchy(mapDataStore, (d: any) => {
			if (hasArrayChildren(d)) {
				return d.children;
			}
			return [];
		});
		const courseRefArray = d3Tree()(root).descendants();
		const courseNameList = courseRefArray.map((course: any) => course.data.tracker);

		const { alignmentCoursesStore } = get();
		const prunedAlignmentStore = alignmentCoursesStore?.filter((course: any) =>
			courseNameList.includes(course.course_id)
		);

		const courseHashMapStore: any = d3Group(courseRefArray, (d: any) => d?.data.tracker);
		set({ mapDataStore, courseHashMapStore, prunedAlignmentStore });
	},
	updateDisciplines: (discipline) => {
		const { disciplines } = get();

		const updatedDisciplines = markSelected(disciplines, discipline) as Discipline[];

		set({ disciplines: updatedDisciplines });
	},
	updateAlignmentCourses: (alignmentCourses: Course[]) => {
		set({ alignmentCoursesStore: alignmentCourses });
	},
	divisions: [],
	updateDivisions: (division) => {
		const { divisions } = get();

		const updatedDivisions = markSelected(divisions, division) as Division[];

		set({ divisions: updatedDivisions });
	},
	details: {
		showApOnly: false,
		showDescription: true,
		showOutcomes: true,
		showResources: true,
		showUnits: true,
	},
	toggleBoolean: (value) => {
		const flag = get().details[value];

		set({ details: { ...get().details, [value]: !flag } });
	},
	toggleAllDisciplines: () => {
		const { disciplines } = get();

		const allFalse = disciplines.every((d) => !d.isSelected);

		set({
			disciplines: disciplines.map((d) => ({ ...d, isSelected: allFalse })),
		});
	},
}));
