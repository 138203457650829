/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useGetDisciplines } from '@/Pages/Disciplines/DisciplinesContainers';
import { EditIcon, TrashCanIcon } from '@/icons/index';
import { StyledPrimaryButton, StyledColorIndicator } from '@/Shared/StyledElements';
import { useUserRole } from '@/hooks/useAuth';
import FadeIn from '@/components/animations/FadeIn';
import Tag from '@/components/Tag/Tag';
import TableFilters from '@/components/TableFilters/TableFilters';
import Table from '@/components/Table';
import Loader from '@/components/Loader';
import { showErrorToast, showSuccessToast } from '@/components/ToastNotification';
import ConfirmModal from '@/components/ConfirmModal/ConfirmModal';
import { StyledActionsContainer, StyledUtilsContainer } from './TagListStyles';
import TagListModal from './TagListModal';
import { deleteTagFrag, useGetTags } from './TagListContainers';

const TagList = () => {
	const [modalActive, setModalActive] = useState(false);
	const [modalType, setModalType] = useState(false);
	const [currentTag, setCurrentTag] = useState<CourseTag>({
		tag_color: 'hsl(281, 50%, 50%)',
		tag_name: '',
		tag_id: '',
		tag_definition: '',
		tag_discipline: '',
	});
	const [isConfirming, setIsConfirming] = useState(false);
	const [allTags, setAllTags] = useState<CourseTag[]>([]);
	const { data: tags, isFetching, isLoading } = useGetTags();
	const { data: disciplines, isLoading: isDisciplineLoading } = useGetDisciplines();
	const { data: userRoleData } = useUserRole();
	const queryClient = useQueryClient();

	const triggerModal = useCallback(() => {
		setModalActive(!modalActive);
	}, [modalActive]);

	const [filteredData, setFilteredData] = useState([]);

	const handleSetData = useCallback((queryTagData: any) => {
		setFilteredData(queryTagData);
	}, []);

	const { mutate: deleteTagMutation } = useMutation({
		mutationFn: deleteTagFrag,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['get-all-tags'],
			});
			showSuccessToast('Tag Deleted');
		},
		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const removeTag = () => {
		deleteTagMutation({
			id: currentTag.tag_id,
			role: userRoleData?.user_role,
		});
	};

	const confirmDelete = useCallback((tag: CourseTag) => {
		setCurrentTag(tag);
		setIsConfirming(true);
	}, []);

	useEffect(() => {
		const abortController = new AbortController();
		setAllTags(tags);
		return () => {
			abortController.abort();
		};
	}, [tags]);

	const queryTagData: TagTableItem[] = useMemo(
		() =>
			allTags?.map((tag: CourseTag) => ({
				idColumn: tag.tag_id,
				nameColumn: tag.tag_name,
				colorColumn: tag.tag_color,
				actionsColumn: tag,
			})),
		[allTags]
	);

	const columns = useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'nameColumn',
				sortType: 'basic',
				Cell(tableData: TagTableData) {
					const { rowData } = tableData;
					const { idColumn, nameColumn, colorColumn } = rowData;
					return <Tag key={idColumn} color={colorColumn} text={nameColumn} />;
				},
			},
			{
				Header: 'Color',
				accessor: 'colorColumn',
				sortType: 'basic',
				alignment: 'center',
				Cell(tableData: TagTableData) {
					const { rowData } = tableData;
					const { colorColumn } = rowData;
					return <StyledColorIndicator color={colorColumn} />;
				},
			},
			{
				Header: 'Actions',
				accessor: 'actions',
				alignment: 'center',
				Cell(cellData: any) {
					const { rowData } = cellData;
					const { actionsColumn } = rowData;

					const updateTagModal = () => {
						triggerModal();
						setModalType(true);
						setCurrentTag(actionsColumn);
						setModalActive(!modalActive);
					};

					return (
						<StyledActionsContainer>
							<EditIcon width={18} passedEvent={updateTagModal} />
							<TrashCanIcon passedEvent={() => confirmDelete(actionsColumn)} />
						</StyledActionsContainer>
					);
				},
			},
		],
		[triggerModal, modalActive, confirmDelete]
	);

	if (isLoading || isDisciplineLoading) return <Loader />;

	return (
		<>
			{modalActive && (
				<TagListModal
					modalActive={modalActive}
					triggerModal={triggerModal}
					isEditing={modalType}
					tagData={currentTag}
					tagList={queryTagData}
					disciplines={disciplines}
				/>
			)}
			{isConfirming && (
				<ConfirmModal
					modalActive={isConfirming}
					onConfirm={removeTag}
					onCancel={() => setIsConfirming(false)}
					triggerModal={() => setIsConfirming(false)}
					message="Are you sure you want to delete this tag?"
					selectedData={currentTag.tag_name}
				/>
			)}
			<StyledUtilsContainer>
				<StyledPrimaryButton
					size="small"
					onClick={() => {
						triggerModal();
						setModalType(false);
					}}>
					Add New Tag
				</StyledPrimaryButton>
				{queryTagData && (
					<TableFilters
						data={queryTagData}
						handleSetData={handleSetData}
						searchColumn={['nameColumn']}
						filteredColumn="nameColumn"
						resultQty={filteredData.length}
						dropdown={false}
					/>
				)}
			</StyledUtilsContainer>
			{!isFetching && (
				<FadeIn style={{ height: '100%' }}>
					<Table data={filteredData} columns={columns} />
				</FadeIn>
			)}
		</>
	);
};

export default TagList;
