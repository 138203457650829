import React, { useContext } from 'react';
import { useIsMutating } from '@tanstack/react-query';
import { SetStatus } from '@/Enums/enum';
import { StyledButton, StyledPrimaryButton, StyledSecondaryButton } from '@/Shared/StyledElements';
import { useUserRole } from '@/hooks/useAuth';
import CourseEditContext from '@/Pages/Courses/CourseEditContext';

const CourseFooter = () => {
	const isMutatingPosts = useIsMutating({ mutationKey: ['submission-status'] });
	const { updateDraftStatus } = useContext(CourseEditContext);
	const { data: userRoleData } = useUserRole();

	return (
		<>
			{isMutatingPosts === 0 && (
				<>
					<StyledSecondaryButton
						type="button"
						className="reject-btn"
						onClick={() => updateDraftStatus(SetStatus.REJECTED)}>
						Request Changes
					</StyledSecondaryButton>
					<StyledPrimaryButton
						type="button"
						onClick={() => updateDraftStatus(SetStatus.APPROVED)}
						className="approve-btn"
						size="large">
						Approve
					</StyledPrimaryButton>
					{userRoleData.user_role === 'admin' && (
						<StyledPrimaryButton
							type="button"
							onClick={() => updateDraftStatus(SetStatus.APPROVED, true)}
							className="approve-all-btn"
							size="large">
							Approve All
						</StyledPrimaryButton>
					)}
				</>
			)}
			{isMutatingPosts !== 0 && (
				<StyledButton
					color="wine"
					type="button"
					disabled
					className="saving-btn"
					size="large">
					Saving...
				</StyledButton>
			)}
		</>
	);
};

export default CourseFooter;
