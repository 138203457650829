import { gql, GraphQLClient } from 'graphql-request';
import { getAuth } from 'firebase/auth';
import { prefix, renameAndDestructure } from '@/utils';

interface NewCourseEntity {
	disciplineId: string;
	divisionId: string;
	courseName: string;
	grade: string;
	isAp: boolean;
}

export const addNewCourse = async (variables: NewCourseEntity) => {
	const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken();
	graphQLClient.setHeader('authorization', `Bearer ${token}`);
	graphQLClient.setHeader('content-type', `application/json`);
	graphQLClient.setHeader('x-hasura-role', 'admin');
	const addCourseMutation = gql`
		mutation AddCourse($courseName: String!, $grade: String!, $isAp: Boolean!) {
			${prefix}insert_courses_one(object: { course_name: $courseName, grade: $grade, is_ap: $isAp }) {
				course_id
			}
		}
	`;

	const result = await graphQLClient.request(addCourseMutation, {
		courseName: variables.courseName,
		grade: variables.grade,
		isAp: variables.isAp,
	});

	const { insert_courses_one: course } = renameAndDestructure(result, prefix);

	const courseDisciplineMutation = gql`
		mutation UpdateCourseDiscipline($courseId: uuid!, $disciplineId: uuid!) {
			${prefix}insert_courses_disciplines(
				objects: { discipline_id: $disciplineId, course_id: $courseId }
				on_conflict: {
					constraint: courses_disciplines_course_id_key
					update_columns: discipline_id
					where: { course_id: { _eq: $courseId } }
				}
			) {
				affected_rows
			}
		}
	`;

	await graphQLClient.request(courseDisciplineMutation, {
		disciplineId: variables.disciplineId,
		courseId: course.course_id,
	});

	const courseDivisionMutation = gql`
		mutation UpdateCourseDivision($courseId: uuid!, $divisionId: uuid!) {
			${prefix}insert_courses_divisions(
				objects: { division_id: $divisionId, course_id: $courseId }
				on_conflict: {
					constraint: courses_divisions_course_id_key
					update_columns: division_id
					where: { course_id: { _eq: $courseId } }
				}
			) {
				affected_rows
			}
		}
	`;

	const data = await graphQLClient.request(courseDivisionMutation, {
		divisionId: variables.divisionId,
		courseId: course.course_id,
	});

	const response = renameAndDestructure(data, prefix);
	return response;
};
