import { getAuth } from 'firebase/auth';
import { gql, GraphQLClient } from 'graphql-request';
import { useQuery } from '@tanstack/react-query';
import { prefix, renameAndDestructure } from '@/utils';

const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);
interface TagDeleteMutation {
	id: string;
	role: string;
}

export const deleteTagFrag = async (variables: TagDeleteMutation) => {
	const endpoint = `${import.meta.env.VITE_HASURA_ENDPOINT}`;
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken();
	const { role, ...vars } = variables;

	const graphQLClient = new GraphQLClient(endpoint, {
		headers: {
			Authorization: `Bearer ${token}`,
			'x-hasura-role': role,
		},
	});

	const mutation = gql`
		mutation DeleteTag($id: uuid!) {
			${prefix}delete_tags(where: { tag_id: { _eq: $id } }) {
				affected_rows
			}
		}
	`;

	return graphQLClient.request(mutation, vars);
};
interface TagMutation {
	color: string;
	name: string;
	definition: string;
	role: string;
	discipline: string;
}

export const addNewTagFrag = async (variables: TagMutation) => {
	const endpoint = `${import.meta.env.VITE_HASURA_ENDPOINT}`;
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken();
	const { role, ...vars } = variables;

	const graphQLClient = new GraphQLClient(endpoint, {
		headers: {
			Authorization: `Bearer ${token}`,
			'x-hasura-role': role,
		},
	});

	const mutation = gql`
		mutation AddNewTag(
			$color: String!
			$name: String!
			$definition: String
			$discipline: String
		) {
			${prefix}insert_tags(
				objects: {
					tag_color: $color
					tag_name: $name
					tag_definition: $definition
					tag_discipline: $discipline
				}
			) {
				affected_rows
			}
		}
	`;

	return graphQLClient.request(mutation, vars);
};

interface EditTagMutation extends TagMutation {
	id: string;
}

export const editTagFrag = async (variables: EditTagMutation) => {
	const endpoint = `${import.meta.env.VITE_HASURA_ENDPOINT}`;
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken();
	const { role, ...vars } = variables;

	const graphQLClient = new GraphQLClient(endpoint, {
		headers: {
			Authorization: `Bearer ${token}`,
			'x-hasura-role': role,
		},
	});

	const mutation = gql`
		mutation EditTag(
			$id: uuid!
			$color: String!
			$name: String!
			$definition: String
			$discipline: String
		) {
			${prefix}update_tags(
				where: { tag_id: { _eq: $id } }
				_set: {
					tag_color: $color
					tag_name: $name
					tag_definition: $definition
					tag_discipline: $discipline
				}
			) {
				affected_rows
			}
		}
	`;

	return graphQLClient.request(mutation, vars);
};

export function useGetTags() {
	return useQuery({
		queryKey: ['get-all-tags'],

		queryFn: async () => {
			const auth = getAuth();
			const token = await auth.currentUser?.getIdToken();
			graphQLClient.setHeader('authorization', `Bearer ${token}`);
			graphQLClient.setHeader('content-type', `application/json`);
			graphQLClient.setHeader('x-hasura-role', 'admin');
			const result = await graphQLClient.request(
				gql`
					query GetAllTags {
						${prefix}tags(order_by: { tag_name: asc }) {
							tag_id
							tag_color
							tag_name
							tag_definition
							tag_discipline
						}
					}
				`
			);
			const { tags } = renameAndDestructure(result, prefix);
			return tags;
		},

		staleTime: Infinity,
		refetchOnMount: 'always',
	});
}
