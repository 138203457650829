/* eslint-disable react/no-unstable-nested-components */
import { useState, useCallback, useMemo, useEffect } from 'react';
import { httpsCallable, getFunctions } from 'firebase/functions';
import { v4 as uuidv4 } from 'uuid';
import { AuthError } from 'firebase/auth';

import { useQueryClient } from '@tanstack/react-query';
import { EditIcon, TrashCanIcon } from '@/icons/index';
import { StyledPrimaryButton } from '@/Shared/StyledElements';
import FadeIn from '@/components/animations/FadeIn';
import Loader from '@/components/Loader';
import { showErrorToast, showSuccessToast } from '@/components/ToastNotification';
import ConfirmModal from '@/components/ConfirmModal/ConfirmModal';
import TableFilters from '@/components/TableFilters/TableFilters';
import Table from '@/components/Table';
import { prefix } from '@/utils';
import UserModal from './UserModal/UsersModal';
import { useGetUsers, useDeptChairs } from './UserContainers';
import { StyledActionsContainer } from './UserStyles';
import CreateUserModal from './CreateUserModal/CreateUserModal';

const Users = () => {
	const [modalActive, setModalActive] = useState(false);
	const [createUserModalActive, setCreateUserModalActive] = useState(false);
	const [isConfirming, setIsConfirming] = useState(false);
	const { data: userData, isFetching, isLoading } = useGetUsers();
	const [userList, setUserList] = useState([]);
	const { data: deptChairData } = useDeptChairs();
	const [deptChairList, setDeptChairList] = useState([]);
	const queryClient = useQueryClient();
	const [currentUser, setCurrentUser] = useState<User>({
		user_email: '',
		user_firebase_id: '',
		user_first: '',
		user_id: '',
		user_last: '',
		user_role: '',
		dept_chair: {
			user_first: '',
			user_id: '',
			user_last: '',
			user_firebase_id: '',
		},
		courses_users: [],
	});

	useEffect(() => {
		const abortController = new AbortController();
		setUserList(userData);
		setDeptChairList(deptChairData);

		return () => {
			abortController.abort();
		};
	}, [userData, deptChairData]);

	const queryUserData = useMemo(
		() =>
			userList?.map((user: User) => ({
				nameColumn: `${user.user_first ?? ''} ${user.user_last ?? ''}`,
				courseColumn: user.courses_users,
				emailColumn: user.user_email,
				roleColumn: user.user_role,
				deptChairColumn: user.dept_chair,
				actionsColumn: user,
				idColumn: user.user_id,
			})),
		[userList]
	);

	const triggerModal = useCallback(() => {
		setModalActive(!modalActive);
	}, [modalActive]);

	const triggerCreateModal = useCallback(() => {
		setCreateUserModalActive(!createUserModalActive);
	}, [createUserModalActive]);

	const [filteredData, setFilteredData] = useState(queryUserData);

	const handleSetData = useCallback((data: any) => {
		setFilteredData(data);
	}, []);

	const confirmDelete = useCallback((user: any) => {
		setCurrentUser(user);
		setIsConfirming(true);
	}, []);

	const deleteUser = async () => {
		const { user_firebase_id: firebaseId } = currentUser;

		const firebaseFunction = getFunctions();
		const removeUser = httpsCallable(firebaseFunction, 'deleteUserFromUI');

		try {
			await removeUser({
				id: firebaseId,
				prefix,
			});
			showSuccessToast('User Deleted');
			queryClient.invalidateQueries({
				queryKey: ['get-users'],
			});
		} catch (error) {
			showErrorToast((error as AuthError).message);
		}
	};

	const columns = useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'nameColumn',
				sortType: 'basic',
				fixedWidth: '20%',
			},
			{
				Header: 'Role',
				accessor: 'roleColumn',
				sortType: 'basic',
			},
			{
				Header: 'Courses',
				accessor: 'assignedCourseColumn',
				sortType: 'basic',
				fixedWidth: '20%',
				Cell(props: any) {
					const { rowData } = props;
					const courseArray = rowData.courseColumn;
					return courseArray.map((c: CourseUserEntity) => {
						const { course } = c;
						return <div key={uuidv4()}>{course?.course_name}</div>;
					});
				},
			},
			{
				Header: 'Dept. Chair',
				accessor: 'deptChairColumn',
				sortType: 'basic',
				fixedWidth: '20%',
				Cell(props: any) {
					const { rowData } = props;
					const deptChair = rowData.deptChairColumn;
					if (deptChair) {
						const firstName = deptChair.user_first ?? '';
						const lastName = deptChair.user_last ?? '';
						return (
							<div>
								{firstName} {lastName}
							</div>
						);
					}
					return <div />;
				},
			},
			{
				Header: 'Email',
				accessor: 'emailColumn',
				sortType: 'basic',
			},
			{
				Header: 'Actions',
				accessor: 'actions',
				alignment: 'center',
				Cell(props: any) {
					const { rowData } = props;
					const updateUserModal = () => {
						triggerModal();
						setCurrentUser(rowData.actionsColumn);
					};

					return (
						<StyledActionsContainer>
							<EditIcon width={18} passedEvent={updateUserModal} />
							<TrashCanIcon
								passedEvent={() => confirmDelete(rowData.actionsColumn)}
							/>
						</StyledActionsContainer>
					);
				},
			},
		],
		[triggerModal, confirmDelete]
	);

	if (isLoading) return <Loader />;

	return (
		<>
			{modalActive && (
				<UserModal
					modalData={currentUser}
					modalActive={modalActive}
					triggerModal={triggerModal}
					deptChairList={deptChairList}
				/>
			)}
			{createUserModalActive && (
				<CreateUserModal
					modalActive={createUserModalActive}
					triggerCreateModal={triggerCreateModal}
					deptChairList={deptChairList}
				/>
			)}
			<StyledPrimaryButton
				size="small"
				onClick={() => setCreateUserModalActive(!createUserModalActive)}>
				Add New User
			</StyledPrimaryButton>
			{isConfirming && (
				<ConfirmModal
					modalActive={isConfirming}
					onConfirm={deleteUser}
					onCancel={() => setIsConfirming(false)}
					triggerModal={() => setIsConfirming(false)}
					message="Are you sure you want to delete this user?"
					selectedData={`${currentUser.user_first} ${currentUser.user_last}`}
				/>
			)}
			{queryUserData && (
				<TableFilters
					data={queryUserData}
					handleSetData={handleSetData}
					searchColumn={['nameColumn', 'emailColumn']}
					filteredColumn="roleColumn"
					resultQty={filteredData.length}
				/>
			)}
			{!isFetching && (
				<FadeIn style={{ height: '100%' }}>
					<Table data={filteredData} columns={columns} />
				</FadeIn>
			)}
		</>
	);
};

export default Users;
