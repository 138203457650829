/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
import { useIsMutating, useMutation, useQueryClient } from '@tanstack/react-query';
import { gql, GraphQLClient } from 'graphql-request';
import { getAuth } from 'firebase/auth';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { EditIcon, MinusIcon, ApprovalsIcon } from '@/icons/index';
import { SetStatus } from '@/Enums/enum';
import { StyledPrimaryButton } from '@/Shared/StyledElements';
import { StyledH4 } from '@/Shared/Typography/typography';
import { useUserRole } from '@/hooks/useAuth';
import FadeIn from '@/components/animations/FadeIn';
import Loader from '@/components/Loader';
import { showErrorToast } from '@/components/ToastNotification';
import ConfirmModal from '@/components/ConfirmModal/ConfirmModal';
import { useHashScrollTo } from '@/hooks/useHashScrollTo';
import StatusCircle from '@/components/StatusCircle/StatusCircle';
import Table from '@/components/Table';
import TableFilters from '@/components/TableFilters/TableFilters';
import { prefix, renameAndDestructure } from '@/utils';
import { StyledActionsContainer, StyledOverlay } from './SubmissionsStyles';
import { useGetAllSubmissions, massApproveSubmissions } from './SubmissionsContainers';

const Submissions = () => {
	const { data: userRoleData } = useUserRole();
	const [allSubmissions, setSubmissions] = useState([]);
	const { data: submissionData, isFetching, isFetched } = useGetAllSubmissions();
	const [isConfirming, setIsConfirming] = useState(false);
	// scroll to course if hash is set in url
	useHashScrollTo();

	useEffect(() => {
		const abortController = new AbortController();
		setSubmissions(submissionData);

		return () => {
			abortController.abort();
		};
	}, [submissionData, userRoleData]);

	const updateCourseSubId = async (variables: { [key: string]: string }) => {
		const endpoint = `${import.meta.env.VITE_HASURA_ENDPOINT}`;
		const auth = getAuth();
		const token = await auth.currentUser?.getIdToken();

		const graphQLClient = new GraphQLClient(endpoint, {
			headers: {
				Authorization: `Bearer ${token}`,
				'x-hasura-role': userRoleData?.user_role,
			},
		});

		const { courseId, subId } = variables;

		const courseMutation = gql`
			mutation UpdateCourseSubId($courseId: uuid!) {
				${prefix}update_courses(
					where: { course_id: { _eq: $courseId } }
					_set: { submission_id: null }
				) {
					affected_rows
				}
			}
		`;

		const subMutation = gql`
			mutation DeleteSubmission($subId: uuid!) {
				${prefix}delete_submissions(where: { submission_id: { _eq: $subId } }) {
					affected_rows
				}
			}
		`;

		await graphQLClient.request(courseMutation, { courseId });

		const data = await graphQLClient.request(subMutation, { subId });
		const result = renameAndDestructure(data, prefix);
		return result;
	};

	const queryClient = useQueryClient();
	const { mutate: courseIdMutate } = useMutation({
		mutationFn: updateCourseSubId,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['get-all-subs'],
			});
		},
		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const dismissRow = useCallback(
		async (subId: string, courseId: string) => {
			courseIdMutate({ courseId, subId });
		},
		[courseIdMutate]
	);

	const querySubmissionData = useMemo(
		() =>
			allSubmissions &&
			allSubmissions?.map((submission: CourseSubmission) => ({
				courseColumn: submission.course.course_name,
				teacherColumn: submission,
				statusColumn: {
					adminApproval: submission.admin_approval,
					deptApproval: submission.dept_approval,
				},
				actionsColumn: submission,
				idColumn: submission.submission_id,
			})),
		[allSubmissions]
	);

	const [filteredData, setFilteredData] = useState(querySubmissionData);

	const handleSetData = useCallback((submissions: any) => {
		setFilteredData(submissions);
	}, []);

	const columns = useMemo(
		() => [
			{
				Header: 'Course',
				accessor: 'courseColumn',
				sortType: 'basic',
				fixedWidth: '20%',
			},
			{
				Header: 'Teacher',
				accessor: 'teacherColumn',
				sortType: 'basic',
				fixedWidth: '20%',
				Cell(props: { [key: string]: any }) {
					const { rowData } = props;
					const { courses_users: courseUsers } = rowData.actionsColumn.course;

					if (courseUsers.length !== 0) {
						return courseUsers.map((courseUser: CourseUserEntity, i: number) => {
							const { user } = courseUser;
							return (
								<div key={`${courseUser.course_id}-${i.toString()}`}>
									{`${user?.user_first ?? ''} ${user?.user_last ?? ''}`}
								</div>
							);
						});
					}
					return <div />;
				},
			},
			{
				Header: 'Status',
				accessor: 'statusColumn',
				sortType: 'basic',
				fixedWidth: '30%',
				alignment: 'center',
				Cell(cellData: { [key: string]: any }) {
					const { value } = cellData;

					return (
						<StatusCircle
							approvalOne={value.deptApproval}
							approvalTwo={value.adminApproval}
						/>
					);
				},
			},
			{
				Header: 'Actions',
				accessor: 'actions',
				alignment: 'center',
				fixedWidth: '30%',
				Cell(cellData: { [key: string]: any }) {
					const { deptApproval, adminApproval } = cellData.rowData.statusColumn;
					const { actionsColumn } = cellData.rowData;
					const { course, submission_id: submissionId } = actionsColumn;
					const courseId = course.course_id;

					const isDismissible = [deptApproval, adminApproval].every(
						(status: string) => status === SetStatus.APPROVED
					);

					return (
						<StyledActionsContainer>
							{(userRoleData?.user_role === 'admin' ||
								userRoleData?.user_role === 'dept') && (
								<Link to={`${courseId}?isApprovalMode=true`}>
									<ApprovalsIcon width={18} />
								</Link>
							)}
							<Link to={`${courseId}`}>
								<EditIcon width={18} />
							</Link>
							{(isDismissible || userRoleData?.user_role === 'admin') && (
								<MinusIcon dismissRow={() => dismissRow(submissionId, courseId)} />
							)}
						</StyledActionsContainer>
					);
				},
			},
		],
		[dismissRow, userRoleData]
	);

	const isSubmittingPosts = useIsMutating({ mutationKey: ['mass-approve-submissions'] });

	const { mutate: massApproveMutation } = useMutation({
		mutationFn: () => massApproveSubmissions(submissionData),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['get-all-subs'],
			});
		},
		onError: () => {
			showErrorToast('All submissions not approved... Please try again.');
		},
	});

	const canApproveAll = () => {
		if (submissionData?.length === 0) {
			return true;
		}

		const allApproved: boolean = submissionData?.every(
			(submission: CourseSubmission) =>
				submission.admin_approval === SetStatus.APPROVED &&
				submission.dept_approval === SetStatus.APPROVED
		);

		return allApproved;
	};

	if (!isFetched) return <Loader />;

	return (
		<>
			{userRoleData?.user_role === 'admin' && (
				<StyledPrimaryButton
					size="small"
					style={{ display: 'flex' }}
					disabled={isSubmittingPosts !== 0 || canApproveAll()}
					onClick={() => setIsConfirming(true)}>
					Approve All Submissions
				</StyledPrimaryButton>
			)}
			{isConfirming && (
				<ConfirmModal
					modalActive={isConfirming}
					onConfirm={massApproveMutation}
					onCancel={() => setIsConfirming(false)}
					triggerModal={() => setIsConfirming(false)}
					selectedData="This process could take some time..."
					message="Are you sure you want to approve all submissions?"
				/>
			)}
			{querySubmissionData && (
				<TableFilters
					data={querySubmissionData}
					handleSetData={handleSetData}
					searchColumn={['courseColumn', 'teacherColumn', 'statusColumn']}
					filteredColumn="statusColumn"
					dropdown={false}
					resultQty={filteredData.length}
				/>
			)}
			{!isFetching && (
				<FadeIn style={{ height: '100%' }}>
					<Table data={filteredData} columns={columns} />
				</FadeIn>
			)}
			{isSubmittingPosts !== 0 && (
				<FadeIn>
					<StyledOverlay>
						<svg viewBox="0 0 50 165" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M22 3c14.91 0 27 11.9 27 26.577v60.147c0 12.901-4.654 17.41-18.05 30.391-1.845 1.787-3.854 3.734-6.04 5.885V33.388l-.097-.482c-.069-.343-.216-.517-.38-.709a3.25 3.25 0 01-.202-.256c-.579-.835-2.231-.964-2.231-.964V3z"
								fill="#03C5AF"
							/>
							<path
								d="M19.227 133.585v6.261l-.677-.581c-6.846-6.481-15.777-14.862-15.866-14.947a2.102 2.102 0 01-.015-3.09c.898-.858 2.358-.863 3.265-.014.083.077 6.735 6.17 13.293 12.371z"
								fill="#484969"
							/>
							<path
								d="M19.227 159.599c0 1.326 1.285 2.401 2.871 2.401s2.871-1.075 2.871-2.401v-8.195c1.017-1.014 1.974-1.97 2.91-2.906l.007-.006c1.925-1.925 3.763-3.761 5.85-5.828a2.767 2.767 0 00.01-3.925 2.79 2.79 0 00-3.94-.011c-1.244 1.232-1.813 1.838-2.4 2.463a87.26 87.26 0 01-2.437 2.508v-2.279h.058a7494.793 7494.793 0 0118.788-18.627l.002-.001.358-.354a2.767 2.767 0 00.017-3.925 2.792 2.792 0 00-3.94-.017c-.1.097-7.368 7.215-15.283 15.089V33.546C24.97 32.22 23.684 31 22.1 31c-1.587 0-2.872 1.22-2.872 2.546v108.226c-1.017-.956-1.579-1.522-2.127-2.074-.559-.562-1.103-1.111-2.102-2.044a2.095 2.095 0 00-3.023.143 2.247 2.247 0 00.146 3.111c1.264 1.184 2.433 2.396 3.605 3.611 1.135 1.177 2.273 2.356 3.501 3.514v11.566z"
								fill="#484969"
							/>
							<path
								d="M19.136 125V33.518c0-1.328 1.283-2.55 2.864-2.55V3c-5.273 0-9.546 6.45-9.546 14.404v16.661c-.614.46-1.253.978-1.947 1.554C4.782 40.362 1 48.551 1 57.86v31.357c0 13.091 3.251 17.155 13.932 30.506l.002.001c1.29 1.613 2.688 3.361 4.202 5.277z"
								fill="#9190B4"
							/>
						</svg>
						<StyledH4>Approving All Submissions...</StyledH4>
					</StyledOverlay>
				</FadeIn>
			)}
		</>
	);
};

export default Submissions;
