import React, { useRef, useState, MouseEvent } from 'react';
import scrollIntoView from 'scroll-into-view';
import Fuse from 'fuse.js';

import { NodeEntity } from '@/components/CourseMap/coursemap.types';
import useOutsideClick from '@/hooks/useOutsideClick';
import { SearchIcon } from '@/icons/index';
import { useAlignmentStore } from '@/stores/alignmentStore';
import { StyledAutoComplete, StyledWrapper } from './AlignmentSearch.Styles';

const AlignmentSearch = () => {
	const courseSearchRef = useRef<HTMLDivElement>(document.createElement('div'));
	const inputRef = useRef<HTMLInputElement>(document.createElement('input'));
	const { courseHashMapStore, prunedAlignmentStore } = useAlignmentStore();
	const [suggestionsArray, setSuggestionsArray] = useState<any[]>();
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [userInput, setUserInput] = useState('');
	const { disciplines, divisions } = useAlignmentStore();

	const options = {
		useExtendedSearch: true,
		threshold: 0.0,
		findAllMatches: true,
		shouldSort: true,
		ignoreLocation: true,
		keys: [
			'course_name',
			'grade',
			'course_description',
			'course_discipline',
			'course_division',
			'course_extras',
			'course_prereq',
			'courses_focuses.focus.focuses_skills.skill.skill_text',
			'courses_outcomes.outcome.outcome_text',
			'courses_resources.resource.resource_author',
			'courses_resources.resource.resource_detail',
			'courses_resources.resource.resource_isbn',
			'courses_resources.resource.resource_title',
		],
	};

	const filteredAlignment = prunedAlignmentStore.filter((c) => {
		const disciplineId = c?.courses_discipline?.discipline.id;
		const divisionId = c?.courses_division?.division.id;
		const disciplineSelection = disciplines.find((d) => d.id === disciplineId);
		const divisionSelection = divisions.find((d) => d.id === divisionId);

		if (disciplineSelection?.isSelected && divisionSelection?.isSelected) {
			return c;
		}
		return null;
	});
	const fuse = new Fuse(filteredAlignment, options);

	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.persist();
		const searchValue = event.target.value;
		setUserInput(searchValue);
		const result = fuse.search(event.target.value) as Fuse.FuseResult<any>[];
		setSuggestionsArray(result.map((x) => x.item));
		if (searchValue !== '') {
			setShowSuggestions(true);
		}
		if (searchValue === '') {
			setShowSuggestions(false);
		}
	};

	function handleSelection(card: NodeEntity) {
		const selectedCard = document.querySelector(`.course-${card?.data.tracker}`);
		if (!selectedCard) return;
		const selectedCards = document.querySelectorAll('.selected-in-search');
		selectedCards.forEach((card) => card.classList.remove('selected-in-search'));

		const disciplineCheckbox = document.getElementById(
			`${card.data.discipline}-checkbox`
		) as HTMLInputElement;
		const divisionCheckbox = document.getElementById(
			`${card.data.division}-checkbox`
		) as HTMLInputElement;

		if (disciplineCheckbox && !disciplineCheckbox.checked) {
			disciplineCheckbox.click();
		}

		if (divisionCheckbox && !divisionCheckbox.checked) {
			divisionCheckbox.click();
		}

		const highlightCard = (cardElm: Element) => {
			cardElm.classList.add('selected-in-search');
			setTimeout(() => {
				cardElm.classList.remove('selected-in-search');
			}, 2500);
		};

		const scrollToCard = (cardElm: Element) => {
			scrollIntoView(cardElm as HTMLElement, {
				align: {
					topOffset: 0,
				},
			});
		};

		if (selectedCard) {
			scrollToCard(selectedCard);
			highlightCard(selectedCard);
			return;
		}

		setTimeout(() => {
			const courseCard = document.querySelector(`.course-${card.data.tracker}`);
			if (courseCard) {
				scrollToCard(courseCard);
				highlightCard(courseCard);
			}
		});
	}

	const handleSearchSubmit = (e: React.MouseEvent) => {
		e.preventDefault();
		const currentCourse = suggestionsArray?.find((c) => c.course_name === userInput);

		if (!currentCourse && suggestionsArray?.length !== 0) {
			const selectedNode = courseHashMapStore.get(suggestionsArray?.[0].course_id)?.[0];

			if (selectedNode) {
				handleSelection(selectedNode);
				inputRef.current.value = suggestionsArray?.[0].course_name;
			}
			setShowSuggestions(false);
		}

		if (currentCourse) {
			const selectedNode = courseHashMapStore.get(currentCourse.course_id)?.[0];

			if (selectedNode) {
				handleSelection(selectedNode);
				inputRef.current.value = currentCourse.course_name;
			}
			setShowSuggestions(false);
		}
	};

	const handleOnClick = (e: React.MouseEvent<HTMLElement>, node: Course) => {
		setShowSuggestions(false);
		const selectedNode = courseHashMapStore.get(node.course_id)?.[0];

		if (selectedNode) {
			inputRef.current.value = node.course_name;
			setUserInput(inputRef.current.value);
			handleSelection(selectedNode);
		}
	};

	const handleOnKeyDown = (e: React.KeyboardEvent) => {
		const searchValue = (e.target as HTMLInputElement).value;
		if (e.code === 'Enter') {
			const currentCourse = suggestionsArray?.find((c) => c.course_name === searchValue);

			if (!currentCourse && suggestionsArray?.length !== 0) {
				const selectedNode = courseHashMapStore.get(suggestionsArray?.[0].course_id)?.[0];

				if (selectedNode) {
					handleSelection(selectedNode);
					inputRef.current.value = suggestionsArray?.[0].course_name;
				}
				setShowSuggestions(false);
			}
			if (currentCourse) {
				const selectedNode = courseHashMapStore.get(currentCourse.course_id)?.[0];

				if (selectedNode) {
					handleSelection(selectedNode);
					inputRef.current.value = currentCourse.course_name;
				}
				setShowSuggestions(false);
			}
		}
	};

	function clearSelect() {
		inputRef.current.value = '';
		setShowSuggestions(false);
		setUserInput('');
	}

	useOutsideClick(courseSearchRef, () => {
		setShowSuggestions(false);
	});

	return (
		<StyledWrapper className="search--intro">
			<StyledAutoComplete ref={courseSearchRef}>
				<input
					placeholder="Search by course name or keywords"
					type="text"
					onChange={handleOnChange}
					onKeyDown={handleOnKeyDown}
					ref={inputRef}
				/>
				{userInput !== '' && (
					<button type="button" onClick={clearSelect} className="clear-btn">
						<svg
							aria-hidden="true"
							width="8"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							fill="currentColor">
							<path d="M23.954 21.03l-9.184-9.095 9.092-9.174L21.03-.046l-9.09 9.179L2.764.045l-2.81 2.81L9.14 11.96.045 21.144l2.81 2.81 9.112-9.192 9.18 9.1z" />
						</svg>
						<span className="screen-reader-text">Clear Dropdown</span>
					</button>
				)}
				{showSuggestions && (
					<ul className="suggestions">
						{suggestionsArray?.map((suggestion: Course) => (
							<li
								aria-label={suggestion.course_name}
								aria-hidden
								key={`suggestion-${suggestion.course_id}`}
								onClick={(e: MouseEvent<HTMLElement>) =>
									handleOnClick(e, suggestion)
								}>
								{suggestion.course_name}
							</li>
						))}
						{suggestionsArray?.length === 0 && (
							<li>
								<span>No matching courses found within the selected options.</span>
							</li>
						)}
					</ul>
				)}
			</StyledAutoComplete>
			<button type="submit" onClick={handleSearchSubmit} className="submit-btn">
				<SearchIcon />
			</button>
		</StyledWrapper>
	);
};
export default AlignmentSearch;
