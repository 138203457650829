import React, { Fragment } from 'react';

import { NoDataIcon } from '@/icons/index';
import { StyledH4, StyledH6, StyledP } from '@/Shared/Typography/typography';
import ParentMapContent from './ParentMapContent';

interface ResourcesTabProps {
	courseResource?: CoursesResourcesEntity[] | null;
	courseResourceInfo?: string;
}

const ResourcesTab = ({ courseResource, courseResourceInfo }: ResourcesTabProps) => {
	const mediaResources = courseResource?.filter(
		({ resource }: CoursesResourcesEntity) => resource.resource_type === 'media'
	);
	const textResources = courseResource?.filter(
		({ resource }: CoursesResourcesEntity) => resource.resource_type === 'textbook'
	);
	const hasMediaResources = mediaResources?.length !== 0;
	const hasTextResources = textResources?.length !== 0;

	if (
		(!courseResource && !courseResourceInfo) ||
		(courseResource?.length === 0 && !courseResourceInfo)
	) {
		return (
			<div className="panel-content">
				<NoDataIcon message="This course does not have any listed resources." delay={0} />
			</div>
		);
	}

	return (
		<>
			<ParentMapContent content={courseResourceInfo} />
			{hasMediaResources && (
				<div className="panel-content">
					{mediaResources?.map(({ resource }: CoursesResourcesEntity, idx: number) => {
						const {
							resource_id: id,
							resource_title: title,
							resource_author: author,
							resource_detail: detail,
						} = resource;
						return (
							<Fragment key={id}>
								{idx === 0 && <StyledH4 mb="16px">Media</StyledH4>}
								<div style={{ marginBottom: '24px' }}>
									{title && <StyledP mb="0">Title: {title}</StyledP>}
									{author && <StyledP mb="0">Author: {author}</StyledP>}
									{detail && (
										<StyledP mb="0">
											URL:{' '}
											<a href={detail} rel="noreferrer" target="_blank">
												Media Link
												<svg
													fill="var(--org-color)"
													width="14"
													viewBox="0 0 512 512"
													xmlns="http://www.w3.org/2000/svg">
													<path d="M464 24H336a24 24 0 000 48h70L176 302a24 24 0 0034 34l230-230v70a24 24 0 0048 0V48a24 24 0 00-24-24z" />
													<path d="M464 248a24 24 0 00-24 24v168H72V72h168a24 24 0 0024-24 24 24 0 00-24-24H48a24 24 0 00-24 24v416a24 24 0 0024 24h416a24 24 0 0024-24V272a24 24 0 00-24-24z" />
												</svg>
											</a>
										</StyledP>
									)}
								</div>
							</Fragment>
						);
					})}
				</div>
			)}
			{hasTextResources && (
				<div className="panel-content">
					{textResources?.map(({ resource }: CoursesResourcesEntity, idx: number) => {
						const {
							resource_id: id,
							resource_title: title,
							resource_isbn: isbn,
							resource_author: author,
							resource_detail: detail,
						} = resource;

						return (
							<Fragment key={id}>
								{idx === 0 && <StyledH4 mb="16px">Textbooks</StyledH4>}
								<div style={{ marginBottom: '24px' }}>
									{title && (
										<StyledH6 mb="0">
											<strong style={{ color: 'var(--org-color)' }}>
												{title}
											</strong>
										</StyledH6>
									)}
									{author && (
										<StyledP
											className="line-descriptors"
											mb="0"
											style={{ color: 'var(--org-color)' }}>
											By: {author}
										</StyledP>
									)}
									{detail && (
										<StyledP className="line-descriptors" mb="0">
											Description: {detail}
										</StyledP>
									)}
									{isbn && (
										<StyledP className="line-descriptors" mb="0">
											ISBN: {isbn}
										</StyledP>
									)}
								</div>
							</Fragment>
						);
					})}
				</div>
			)}
		</>
	);
};

export default ResourcesTab;
