import { getAuth } from 'firebase/auth';
import { gql, GraphQLClient } from 'graphql-request';
import { useQuery } from '@tanstack/react-query';
import { prefix, renameAndDestructure } from '@/utils';
import { v4 as uuidv4 } from 'uuid';

const getGraphQLClient = async () => {
	const endpoint = `${import.meta.env.VITE_HASURA_ENDPOINT}`;
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken();

	const graphQLClient = new GraphQLClient(endpoint, {
		headers: {
			Authorization: `Bearer ${token}`,
			'x-hasura-role': 'admin',
		},
	});

	return graphQLClient;
};

interface SettingsMutation {
	id: string;
	statement: string;
	statementTitle: string;
	primaryColor: string;
	banner: string;
	logo: string;
}

export const updateSettings = async (variables: Partial<SettingsMutation>) => {
	const graphQLClient = await getGraphQLClient();

	const mutation = gql`
		mutation UpsertSiteSettings(
			$id: uuid!
			$statementTitle: String
			$statement: String
			$primaryColor: String
			$banner: String
			$logo: String
		) {
			${prefix}insert_site_settings_one(
				object: {
					id: $id
					statement_title: $statementTitle
					statement: $statement
					primary_color: $primaryColor
					banner: $banner
					logo: $logo
				}
				on_conflict: {
					constraint: site_settings_pkey
					update_columns: [statement_title, statement, primary_color, banner, logo]
				}
			) {
				id
				statement_title
				statement
				primary_color
				banner
				logo
			}
		}
	`;

	// Ensure non-null values for required fields and remove empty strings
	const sanitizedVariables = Object.entries({
		...variables,
		id: variables.id || uuidv4(),
		statement: variables.statement || '',
		statementTitle: variables.statementTitle || '',
		primaryColor: variables.primaryColor || '',
		banner: variables.banner || '',
		logo: variables.logo || '',
	}).reduce((acc, [key, value]) => {
		if (value !== undefined) {
			acc[key as keyof SettingsMutation] = value;
		}
		return acc;
	}, {} as SettingsMutation);

	const data = await graphQLClient.request(mutation, sanitizedVariables);
	const { insert_site_settings_one: settings } = renameAndDestructure(data, prefix);
	return settings;
};

export const useGetSiteSettings = () =>
	useQuery({
		queryKey: ['get-site-settings'],

		queryFn: async () => {
			const graphQLClient = await getGraphQLClient();
			const result = await graphQLClient.request(
				gql`
					query GetSiteSettings {
						${prefix}site_settings {
							id
							statement_title
							statement
							primary_color
							banner
							logo
						}
					}
				`
			);
			const { site_settings: settings } = renameAndDestructure(result, prefix);

			if (!settings[0]) {
				return [];
			}
			return settings[0];
		},

		staleTime: Infinity,
		refetchOnMount: 'always',
	});
