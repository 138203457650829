/* eslint-disable no-await-in-loop */
import { useState } from 'react';
import ReactModal from 'react-modal';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { initializeApp, getApp, getApps, FirebaseApp } from 'firebase/app';
import {
	getAuth,
	createUserWithEmailAndPassword,
	setPersistence,
	browserSessionPersistence,
	AuthError,
} from 'firebase/auth';
import { httpsCallable, getFunctions } from 'firebase/functions';
import { useQueryClient } from '@tanstack/react-query';
import { gql, GraphQLClient } from 'graphql-request';

import { CloseIcon } from '@/icons/index';
import { GlobalModalStyle, StyledInput, StyledPrimaryButton } from '@/Shared/StyledElements';
import { StyledH3, StyledP, StyledSubHeader } from '@/Shared/Typography/typography';
import { userDefinedRoles } from '@/Shared/Data/StaticData';
import { useUserRole } from '@/hooks/useAuth';
import CourseTags from '@/components/CourseTags';
import { showSuccessToast, showErrorToast } from '@/components/ToastNotification';
import UserModalPanel from '@/Pages/Users/UserModalPanel/UsersModalPanel';
import { prefix, themeSelectStyles } from '@/utils';
import AsyncSelect from 'react-select/async';
import { FormErrorMessage } from '@/components/FormErrorMessage';
import { isCourseUserEntity } from '../UserInterfaces';
import {
	StyledModalHeader,
	StyledModalBody,
	StyledFooterBody,
	StyledForm,
	StyledCourseContainer,
	modalStyles,
} from './CreateUserModalStyles';

interface UserModalProps {
	triggerCreateModal(): void;
	modalActive: boolean;
	deptChairList: DeptChair[];
}

interface FormValues {
	firstName: string;
	lastName: string;
	email: string;
	role: string;
	deptChairId: any;
}

const CreateUserModal = ({ modalActive, triggerCreateModal, deptChairList }: UserModalProps) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
	} = useForm<FormValues>();
	const queryClient = useQueryClient();

	const [userCourses, setUserCourses] = useState<CourseUserEntity[]>([]);
	const { data: userRoleData } = useUserRole();
	const [isCreating, setIsCreating] = useState(false);

	const createDeptList = () => {
		const formattedList = deptChairList.map((c: DeptChair) => ({
			label: `${c.user_first ?? ''} ${c.user_last ?? ''}`,
			value: `${c.user_first ?? ''} ${c.user_last ?? ''}`,
			id: c.user_firebase_id,
		}));
		return formattedList;
	};

	const handleModalClose = () => {
		if (!isCreating) {
			triggerCreateModal();
		}
	};

	const isEmailUnique = (email: string) => {
		const previousUserData = queryClient.getQueryData(['get-users']) as Partial<User[]>;
		const isEmailUnique = previousUserData.some((c) => c?.user_email === email.trim());
		return isEmailUnique;
	};

	const onSubmit: SubmitHandler<FormValues> = async (formData) => {
		setIsCreating(true);
		const { email, firstName, lastName, role, deptChairId } = formData;
		const generatePassword = () => {
			const length = 16;
			const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
			let retVal = '';
			for (let i = 0, n = charset.length; i < length; i += 1) {
				retVal += charset.charAt(Math.floor(Math.random() * n));
			}
			return retVal;
		};
		const hasAuth = getApps()?.find((app: FirebaseApp) => app.name === 'auth-worker');
		if (!hasAuth) {
			initializeApp(
				{
					apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
					authDomain: import.meta.env.VITE_FIREBASE_AUTHDOMAIN,
					databaseURL: import.meta.env.VITE_FIREBASE_DB_URL,
					projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
					storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
					messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
				},
				'auth-worker'
			);
		}
		const authWorkerApp = getApp('auth-worker');
		const authWorkerAuth = getAuth(authWorkerApp);
		// TODO: update role connections to avoid using delay
		function delay() {
			// eslint-disable-next-line no-promise-executor-return
			return new Promise((resolve) => setTimeout(resolve, 500));
		}
		// disables caching of account credentials
		await setPersistence(authWorkerAuth, browserSessionPersistence);
		try {
			const { user } = await createUserWithEmailAndPassword(
				authWorkerAuth,
				email.trim(),
				generatePassword()
			);
			const firebaseFunction = getFunctions();
			const createUser = httpsCallable(firebaseFunction, 'createUserFromUI');

			interface InsertedUser {
				insert_users: {
					returning: User[];
				};
				update_users: {
					returning: User[];
				};
			}

			interface FirebaseResponse {
				data: { userData: InsertedUser; resetLink: string };
			}

			const firebaseResponse = (await createUser({
				id: user?.uid ?? '',
				firstName: firstName.trim(),
				lastName: lastName.trim(),
				email: email.trim(),
				role,
				deptChair: deptChairId,
				prefix,
			})) as FirebaseResponse;

			const { userData, resetLink } = firebaseResponse.data;

			const url = new URL(resetLink);
			const oobCode = url.searchParams.get('oobCode');
			const apiKey = url.searchParams.get('apiKey');

			const relativeLink = `${
				window.location.origin
			}/set-password?oobCode=${oobCode}&apiKey=${apiKey}&email=${email.trim()}`;

			const emailData = {
				service_id: 'service_3pf8c05',
				template_id: 'template_xm6c5cz',
				user_id: 'user_MTnrIn1vgu5DZSpVYUo53',
				template_params: {
					emailRecipient: email.trim(),
					resetPasswordLink: relativeLink,
					fromName: 'Elevate Support',
					to_name: `${firstName} ${lastName}`,
				},
			};

			// send user an email to finish setting up their account
			const response = await fetch('https://api.emailjs.com/api/v1.0/email/send', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(emailData),
			});

			if (response.status !== 200) {
				throw new Error('Network response was not ok');
			}

			const endpoint = `${import.meta.env.VITE_HASURA_ENDPOINT}`;
			const auth = getAuth();
			const token = await auth.currentUser?.getIdToken();
			const graphQLClient = new GraphQLClient(endpoint, {
				headers: {
					Authorization: `Bearer ${token}`,
					'x-hasura-role': userRoleData?.user_role,
				},
			});
			const addCourseMutation = gql`
				mutation AddUserCourse($courseId: uuid!, $userId: uuid!) {
					${prefix}insert_courses_users(objects: { course_id: $courseId, user_id: $userId }) {
						affected_rows
					}
				}
			`;
			let userId;
			if (userData.insert_users) {
				userId = userData.insert_users.returning[0].user_id;
			} else {
				userId = userData.update_users.returning[0].user_id;
			}

			if (userCourses.length !== 0) {
				for (let i = 0; i < userCourses.length; i += 1) {
					const courseId = userCourses[i].course_id;
					if (courseId) {
						await graphQLClient.request(addCourseMutation, {
							courseId,
							userId,
						});
					}
					if (i % 5 === 0) {
						await delay();
					}
				}
			}
			queryClient.invalidateQueries({
				queryKey: ['get-users'],
			});
			queryClient.invalidateQueries({
				queryKey: ['get-dept-chairs'],
			});
			showSuccessToast('User Added');
		} catch (error) {
			if ((error as AuthError).code === 'auth/email-already-in-use') {
				showErrorToast('This email is already in use.');
			} else {
				showErrorToast('Oh no, something went wrong... Please try again.');
			}
		}
		setIsCreating(false);
	};

	const setTagText = (c: CourseUserEntity | CoursesEntity) => {
		if (isCourseUserEntity(c)) {
			return c.course?.course_name;
		}
		return c.course_name ?? '';
	};

	ReactModal.setAppElement('#root');

	return (
		<ReactModal
			closeTimeoutMS={100}
			isOpen={modalActive}
			onRequestClose={handleModalClose}
			contentLabel="User Modal"
			style={modalStyles}>
			<GlobalModalStyle />
			<StyledModalHeader>
				<CloseIcon passedEvent={handleModalClose} />
				<StyledH3 mb="8px">Add New User</StyledH3>
			</StyledModalHeader>
			<StyledModalBody>
				<div className="input-column">
					<StyledSubHeader mb="16px">Assigned Courses</StyledSubHeader>
					<StyledCourseContainer>
						{userCourses?.length === 0 && (
							<StyledP
								style={{
									display: 'inline-block',
									marginRight: 'var(--spacing-2)',
									minHeight: '33px',
								}}>
								No Courses assigned...
							</StyledP>
						)}
						{userCourses &&
							userCourses.map((course: CourseUserEntity | CoursesEntity) => (
								<CourseTags
									style={{ marginRight: 'var(--spacing-2)' }}
									key={course.course_id}
									text={setTagText(course)}
								/>
							))}
					</StyledCourseContainer>
					<StyledForm onSubmit={handleSubmit(onSubmit)}>
						<Controller
							control={control}
							name="role"
							render={({ field }) => (
								<AsyncSelect
									styles={themeSelectStyles}
									className="select"
									placeholder="Select Role"
									{...register('role', {
										required: 'A role is required',
									})}
									onChange={({ value }) => {
										field.onChange(value);
									}}
									isClearable={false}
									defaultOptions={userDefinedRoles}
									loadOptions={(inputValue) => {
										return new Promise((resolve) => {
											const array = userDefinedRoles;
											const filterResults = array.filter((x) =>
												x.value
													.toLowerCase()
													.includes(inputValue.toLowerCase())
											);
											resolve(filterResults);
										});
									}}
								/>
							)}
						/>
						<FormErrorMessage
							className="error-message"
							isShowing={!!errors?.role}
							message={errors.role?.message}
						/>
						<Controller
							control={control}
							name="deptChairId"
							render={({ field }) => (
								<AsyncSelect
									styles={themeSelectStyles}
									className="select"
									placeholder="Choose Dept. Chair"
									isClearable
									defaultValue="Dept. Chair"
									loadOptions={(inputValue) => {
										return new Promise((resolve) => {
											const array = createDeptList();
											const filterResults = array.filter((x) =>
												x.value
													.toLowerCase()
													.includes(inputValue.toLowerCase())
											);
											resolve(filterResults);
										});
									}}
									defaultOptions={createDeptList()}
									onChange={(option) => {
										field.onChange(option?.id ?? '');
									}}
								/>
							)}
						/>
						<StyledInput
							type="text"
							placeholder="First Name"
							{...register('firstName', {
								required: 'First name is required',
							})}
						/>
						<FormErrorMessage
							className="error-message"
							isShowing={!!errors?.firstName}
							message={errors.firstName?.message}
						/>
						<StyledInput
							type="text"
							placeholder="Last Name"
							{...register('lastName', {
								required: 'Last name is required',
							})}
						/>
						<FormErrorMessage
							className="error-message"
							isShowing={!!errors?.lastName}
							message={errors.lastName?.message}
						/>
						<StyledInput
							type="text"
							placeholder="Email"
							{...register('email', {
								required: 'Email is required',
								pattern: {
									value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}(?:\s+)*$/i,
									message: 'Invalid email address',
								},
								validate: (value) => {
									if (isEmailUnique(value)) {
										return 'This email is already taken';
									}
									return true;
								},
							})}
						/>
						<FormErrorMessage
							className="error-message"
							isShowing={!!errors?.email}
							message={errors.email?.message}
						/>
					</StyledForm>
				</div>
				<div className="panel-column">
					<UserModalPanel
						panelTitle="Course Selection"
						selectedCourses={userCourses ?? []}
						passedEvent={setUserCourses}
					/>
				</div>
			</StyledModalBody>
			<StyledFooterBody>
				<StyledPrimaryButton
					disabled={isCreating}
					type="submit"
					onClick={handleSubmit(onSubmit)}>
					{isCreating ? 'Creating New User' : 'Add User'}
				</StyledPrimaryButton>
			</StyledFooterBody>
		</ReactModal>
	);
};

export default CreateUserModal;
