/* eslint-disable no-param-reassign */
import { getAuth } from 'firebase/auth';
import { gql, GraphQLClient } from 'graphql-request';
import { useQuery } from '@tanstack/react-query';
import { isObjectEmpty, prefix, renameAndDestructure } from '@/utils';

const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);

const getGraphQLClient = async () => {
	const endpoint = `${import.meta.env.VITE_HASURA_ENDPOINT}`;
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken();

	const graphQLClient = new GraphQLClient(endpoint, {
		headers: {
			Authorization: `Bearer ${token}`,
			'x-hasura-role': 'admin',
		},
	});

	return graphQLClient;
};

const eachRecursive = (obj: any, previousName = '', newName = '') => {
	// eslint-disable-next-line
	for (const k in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, k)) {
			if (typeof obj[k] === 'object' && obj[k] !== null) {
				eachRecursive(obj[k], previousName, newName);
			}

			if (k === 'division' && obj[k] === previousName) {
				obj[k] = newName;
			}
		}
	}
	return obj;
};

const updateCourseMapDivisionName = async (previousName: string, newName: string) => {
	const graphQLClient = await getGraphQLClient();
	const courseQuery = gql`
		query GetCourseMap {
			${prefix}course_map(where: { isDraft: { _eq: false } }) {
				course_map_json
			}
		}
	`;

	const result = await graphQLClient.request(courseQuery);
	const { course_map: map } = renameAndDestructure(result, prefix);
	const mapJSON = JSON.parse(map[0]?.course_map_json ?? '{}');

	if (isObjectEmpty(mapJSON)) {
		return { affected_rows: 0 };
	}
	const updatedMap = JSON.stringify(eachRecursive(mapJSON, previousName, newName));

	const mutation = gql`
		mutation UpdateCourseMap($map: String!) {
			${prefix}update_course_map(where: { isDraft: { _eq: false } }, _set: { course_map_json: $map }) {
				affected_rows
			}
		}
	`;

	return graphQLClient.request(mutation, { map: updatedMap });
};

export const deleteDivision = async (variables: Pick<DivisionsMutation, 'id'>) => {
	const graphQLClient = await getGraphQLClient();

	const mutation = gql`
		mutation DeleteDivision($id: uuid!) {
			${prefix}delete_divisions(where: { id: { _eq: $id } }) {
				returning {
					id
				}
			}
		}
	`;

	return graphQLClient.request(mutation, variables);
};

export const updateCourseDivision = async (variables: {
	previousDivision: Division;
	newDivision: Pick<Division, 'id' | 'name'>;
}) => {
	const graphQLClient = await getGraphQLClient();
	const { previousDivision, newDivision } = variables;

	const mutation = gql`
		mutation UpdateCourseDivision($previousDivisionId: uuid!, $newDivisionId: uuid!) {
			${prefix}update_courses_divisions(
				where: { division_id: { _eq: $previousDivisionId } }
				_set: { division_id: $newDivisionId }
			) {
				returning {
					course_id
					courses_divisions_id
					division_id
				}
			}
		}
	`;

	const result = await graphQLClient.request(mutation, {
		previousDivisionId: previousDivision.id,
		newDivisionId: newDivision.id,
	});

	const { update_courses_divisions: updateCoursesDivisions } = renameAndDestructure(
		result,
		prefix
	);

	await deleteDivision({
		id: previousDivision.id,
	});

	if (
		variables.previousDivision?.name !== variables.newDivision.name &&
		variables.previousDivision
	) {
		await updateCourseMapDivisionName(
			variables?.previousDivision?.name ?? '',
			variables.newDivision.name
		);
	}

	return updateCoursesDivisions.returning;
};

interface DivisionsMutation {
	id: string;
	name: string;
	previousState: Division | null;
}

export const updateDivisions = async (variables: DivisionsMutation) => {
	const graphQLClient = await getGraphQLClient();
	const mutation = gql`
		mutation UpdateDivisions($id: uuid!, $name: String!) {
			${prefix}update_divisions(where: { id: { _eq: $id } }, _set: { name: $name }) {
				returning {
					id
					name
					courses_divisions {
						courses_divisions_id
						course_id
						division_id
					}
				}
			}
		}
	`;

	if (variables.previousState?.name !== variables.name && variables.previousState) {
		await updateCourseMapDivisionName(variables?.previousState?.name ?? '', variables.name);
	}

	const result = await graphQLClient.request(mutation, {
		id: variables.id,
		name: variables.name,
	});

	const returnValue = renameAndDestructure(result, prefix);
	return returnValue.update_divisions.returning[0];
};

export const createDivision = async (
	variables: Omit<DivisionsMutation, 'id' | 'previousState'>
) => {
	const graphQLClient = await getGraphQLClient();

	const mutation = gql`
		mutation CreateDivision($name: String!) {
			${prefix}insert_divisions_one(object: { name: $name }) {
				name
				id
			}
		}
	`;
	return graphQLClient.request(mutation, variables);
};

export function useGetDivisions() {
	return useQuery({
		queryKey: ['get-all-divisions'],

		queryFn: async () => {
			graphQLClient.setHeader('content-type', `application/json`);

			const result = await graphQLClient.request(
				gql`
					query GetAllDivisions {
						${prefix}divisions(order_by: { name: asc }) {
							id
							name
							courses_divisions {
								courses_divisions_id
								course_id
								division_id
							}
						}
					}
				`
			);
			const { divisions } = renameAndDestructure(result, prefix);
			return divisions;
		},

		staleTime: Infinity,
		refetchOnMount: 'always',
	});
}

// TODO: add to migrate courses to a new division
// export const migrateDivisions = async () => {
// 	const graphQLClient = await getGraphQLClient();
// 	const type = 'Upper';
// 	const type2 = 'Upper';
// 	const courseQuery = gql`
// 		query Migrate($type: String!, $type2: String!) {
// 			courses(where: { course_division: { _eq: $type } }) {
// 				course_name
// 				course_division
// 				course_id
// 				courses_division {
// 					division {
// 						name
// 					}
// 				}
// 			}
// 			divisions(where: { name: { _eq: $type2 } }) {
// 				name
// 				id
// 			}
// 		}
// 	`;

// 	const a = await graphQLClient.request(courseQuery, { type, type2 });
// 	// eslint-disable-next-line arrow-body-style
// 	const objects = a.courses.map((course: any) => {
// 		return { course_id: course.course_id, division_id: a.divisions[0].id };
// 	});

// 	const mutation = gql`
// 		mutation CourseDiscMut($objects: [courses_divisions_insert_input!]!) {
// 			insert_courses_divisions(
// 				objects: $objects
// 				on_conflict: {
// 					update_columns: division_id
// 					constraint: courses_divisions_course_id_key
// 				}
// 			) {
// 				affected_rows
// 			}
// 		}
// 	`;

// 	return graphQLClient.request(mutation, { objects });
// };
